const regexList = `fnac.com
cultura.com
amazon.com
amazon.fr
cdiscount.com
ebay.fr
rueducommerce.fr
20minutes.fr
cnews.fr
la-croix.com
lefigaro.fr
lemonde.fr
leparisien.fr
lequipe.fr
lesechos.fr
humanite.fr
liberation.fr
lopinion.fr
pepsmagazine.com
alternatives-economiques.fr
archistorm.com
arts-et-gastronomie.com
artsmagazine.fr
astrapi.com
auto-moto.com
autoplus.fr
magazine-avantages.fr
beauxarts.com
bibamagazine.fr
bienetre-et-sante.fr
caminteresse.fr
capital.fr
causette.fr
challenges.fr
closermag.fr
connaissancedesarts.com
cosmopolitan.fr
courrierinternational.com
cuisineactuelle.fr
dailymotion.com
magazine-decideurs.com
detentejardin.com
detoursenfrance.fr
diapasonmag.fr
dossierfamilial.com
drgood.fr
elle.fr
editions-burda.fr
femmeactuelle.fr
flowmagazine.fr
foudecuisine.fr
foudepatisserie.com
francedimanche.fr
francefootball.fr
gala.fr
geo.fr
geoado.com
glamour.com
gourmand.viepartique.fr
gqmagazine.fr
grandprixmag.com
grands-reportages.com
grazia.fr
hbrfrance.fr
hbr.org
healthyfoodcreation.fr
historia.fr
horoscope.fr
imagesdoc.com
investir.lesechos.fr
jaimelire.com
jebouquine.com
journaldesfemmes.fr
journaldugolf.fr
joursdefrance.lefigaro.fr
juliemag.com
lafranceagricole.fr
larecherche.fr
lavie.fr
lamidesjardins.maison-travaux.fr
largus.fr
autojournal.fr
automobile-magazine.fr
lechasseurfrancais.com
lejournaldelamaison.fr
journaldemickey.com
lejdd.fr
canalplus.com
lenouveaudetective.com
lire.fr
leparticulier.lefigaro.fr
lepoint.fr
lerevenu.com
letempsdunvoyage.com
terrevivante.org
letiquette.so
lexpress.fr
lhistoire.fr
nouvelobs.com
lesinrocks.com
magicmaman.com
maison-travaux.fr
maisoncreative.com
cotemaison.fr
management.fr
manon-magazine.com
marianne.net
marieclaire.fr
mariefrance.fr
marmiton.org
maxi-mag.fr
mieuxvivre-votreargent.fr
milkdecoration.com
modesettravaux.fr
monjardinmamaison.maison-travaux.fr
mordelire.com
nationalgeographic.fr
neonmag.fr
notretemps.com
numero.com
okapi.fr
opera-magazine.com
parents.fr
parismatch.com
parisaeroport.fr
lepelerin.com
philomag.com
phosphore.com
picoti-magazine.com
pleinevie.fr
pointdevue.fr
polkamagazine.com
pommedapi.com
popi.fr
premiere.fr
psychologies.com
public.fr
regal.fr
rustica.fr
santemagazine.fr
saveurs-magazine.fr
science-et-vie.com
sciencesetavenir.fr
sofoot.com
society-magazine.fr
sportauto.fr
stylist.fr
systemed.fr
telepoche.fr
telestar.fr
telez.fr
telecablesat.fr
telerama.fr
temposante.fr
tetu.com
topsante.com
toupie-magazine.com
tsugi.fr
valeursactuelles.com
vanityfair.fr
femina.fr
vigneron-mag.com
vital.topsante.com
vivadeco.fr
vogue.fr
voici.fr
vsd.fr
wakou-magazine.com
wapiti-magazine.com
yachtingclassique.com
yam.paris
youpi.fr
monde-diplomatique.fr
revuedesdeuxmondes.fr
youtube.com
youtu.be
vimeo.com
twtch.tv
babelio.com
albin-michel.fr
galllimard.fr
hachette.fr
bayard-jeunesse.com
groupebayard.com
bayard-editions.com
editis.com
editions.flammarion.com
flammarion-jeunesse.fr
decitre.fr
franceloisirs.com
editionsatlas.fr
ecoledesloisirs.fr
eyrolles.com
leseditionsdeminuit.fr
seuil.com
larousse.fr
editions-bordas.fr
michelin.fr
arthaud.fr
editions.nathan.fr
dunod.com
puf.com
editions-harmattan.fr
armand-colin.com
editeurs-reunis.fr
cersa.cnrs.fr
casterman.com
editionsducerf.fr
dargaud.com
editions-delagrave.fr
denoel.fr
editionsdidier.com
magnard.fr
fayard.fr
fleuruseditions.com
fleuruspresse.com
editions-foucher.fr
gautier-languereau.fr
editions-hatier.fr
editions-istra.com
lisez.com
mdi-editions.com
vigotmaloine.com
elsevier-masson.fr
payot-rivages.fr
editions-stock.fr
grasset.fr
lisez.com
editionsdurocher.fr
lerobert.com
actes-sud.fr
glenat.com
editions-spinelle.com
lysbleueditions.com
editions-jclattes.fr
mercuredefrance.fr
michel-lafon.fr
le-livre.fr
feret.com
editions-baudelaire.com
editionsphebus.fr
nouveau-monde.net
lemotetlereste.com
desastre.com
editions-observatoire.com
editions-breal.fr
editionspaulsen.com
editionslesliensquiliberent.fr
jailu.com
editionspoints.com
ed-amphora.fr
folio-lesite.fr
atlantica.fr
editionsdutriomphe.fr
livredepoche.com
parigramme.com
la-pleiade.fr
pocket.fr
collectionreperes.com
librio.net
editions-artemis.com
editions-scala.fr
editionsdelamartiniere.fr
groupetournon.com
petitfute.com
lonelyplanet.com
marabout.com
cnrseditions.fr
calmann-levy.fr
unitheque.com
lecteurs.com
rue-des-livres.com
lelivrequiparle.com
deezer.com
spotify.com
ruedumonde.fr
wikipedia.org
pressesdesciencespo.fr
pressesdesmines.com
presses.ens.fr
catalogue-editions.ens-lyon.fr
editions.ehess.fr
franceculture.fr
francemusique.fr
radioclassique.fr
tsfjazz.com
francebleu.fr
francetvinfo.fr
europe1.fr
franceinter.fr
rmc.bfmtv.com
TF1.fr
france.tv
canalplus.com
6play.fr
arte.tv`

export default regexList
