import fileRegexList from './regexList'
 
export const regexList = fileRegexList
                            .split('\n')
                            .filter(textString=>(textString != ''))
                            .map(textString=>new RegExp(`^https?:\/\/(www.)?(${textString})\/.+$`, 'm'))

export const cleanText = (text) => {

  

  if (!text)
  return null

  const subText1 = /&#039;/g
  const subText2 = /&quot;/g
  const subText3 = /&#39;/g
  const subText4 = /&#x27;/g



  if (text) {
    const tempText1 = text.replace(subText1, `'`)
    const tempText2 = tempText1.replace(subText2, `"`)
    const tempText3 = tempText2.replace(subText3, `'`)
    const tempText4 = tempText3.replace(subText4, `'`)

    return (tempText4)

  }

  return ''


}


