import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isMobile } from 'react-device-detect'
import useStyles, { DiscoverButton } from '../../components/style/pages/eventsSection'
import { navigate } from 'gatsby'
import getDates from '../../helpers/dates'
import Avatar from '@mui/material/Avatar'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import randomColor from 'randomcolor'
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';


const prefix = `https://www.pekoia.com`
//const prefix = "https://promo.d2ktv0q35m6sqx.amplifyapp.com"

const VignetteEvent = ({ data: event, sideData, position, index, parentIsMounted, carouselHeight, blurred, type }) => {


   

    const classes = useStyles()
    const [isMounted, setIsMounted] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [vignetteColor, setVignetteColor] = useState(null)

    const children = event.children
   
    const finalUrl = children?.find(image => (image.original && image.original.src.includes('L') && image.original.src.includes('800') && image.original.src.includes('jpg')))?.original.src
    const color = randomColor({ luminosity: 'light', hue: 'blue' })

    const imageUrl = `${prefix}/static/L${event.imageKey}-500-${event.imageETag500}.jpg`

    
 
    useEffect(() => {


     

        if (!isMounted){
            setIsMounted(true)
            setVignetteColor(color)

        }
            

        if ((position > index - 7) && !isImageLoaded && imageUrl) {
            const img = new Image()

            img.onload = () => {
          
                    setIsImageLoaded(true)
            }
            img.src = imageUrl

        }

    }, [position])

    const formateur = event.children.find(child => child.nom)
    const [spinner, setSpinner] = useState(false)
    const avatarUrl = sideData?.find(elem => elem.original.src.includes(formateur.imageId))?.original.src
    const [showBorder, setShowBorder] = React.useState(false)
    const [hover, setHover] = React.useState(null)

  
    if (!formateur)
        return null


    if (!event.children)
        return null


  

    return (
        <SwiperSlide>
            
            <Box position="relative" width='100%' height={carouselHeight + 'px'} style={hover ? {'transitionDelay': '0.25s', transform: 'scale(1.05)'}:{'transitionDelay': '0.25s', transform: 'scale(1.0)'}}  onClick={() => { setShowBorder(true); setSpinner(true);navigate(event.fields.slug) }} >
                <Box position="absolute" width="100%" height="100%" zIndex={-1} >
                    {parentIsMounted && isImageLoaded && <img src={imageUrl || finalUrl}  alt={event.title1} className={classes.image} style={blurred ?{filter: 'blur(15px)'} : null} />}
                    {parentIsMounted  && !isImageLoaded && <Box height="100%" color={vignetteColor || color} bgcolor={vignetteColor || color} display="flex" alignContent="center" alignItems="center">@</Box>}
                    {!parentIsMounted  && <Box height="100%" color={vignetteColor || color} bgcolor={vignetteColor || color} display="flex" alignContent="center" alignItems="center">
                        @
                    </Box>}



                </Box>
                <Box position="absolute" width="100%" height="100%" zIndex={-0.5}>
                    <Box height="100%" display="flex" flexDirection="row" alignContent="center" alignItems="stretch" style={{ color: 'transparent', backgroundColor: "#00000050" }}>
                        @
                    </Box>


                </Box>

                <Box position="absolute" width="100%" height="100%" zIndex={2} onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}>
                    <Box width='100%' height="100%" display="flex" flexDirection="column" alignItems="center" justifyItems='stretch'>
                        <Box flex={4} width="100%" display="flex" flexDirection="column" position="relative">
                          
                            <Box flex={1} alignSelf='start' display="flex" flexDirection="row" alignItems="center" >
                                {avatarUrl && !blurred &&<Avatar variant="circular" style={{ zIndex: 2, margin: "5px", width: '40px', height: '40px' }}>
                                    <Box position="relative" top='12px'>


                                        <img className={classes.avatar} src={avatarUrl} alt={event.title1}></img>
                                   
                                    </Box>
                                </Avatar>}

                                {avatarUrl && <Box marginLeft='10px' >

                                    <Typography style={{ color: 'white', fontSize: '0.7rem', fontWeight: 700, zIndex: 2 }}>
                                        {!blurred && formateur?.prenom}{" "} {!blurred && formateur?.nom}
                                    </Typography>

                                </Box>}

                            </Box>

                            <Box width="100%" flex={4} display="flex" flexDirection="column" justifyItems="center" justifyContent="space-around">
                                <Box  display="flex" flexDirection="column" justifyContent="space-between"  marginTop="10%">

                                    {(event.replayStatus != 'AVAILABLE' && type == 'CURRENT') && <Box>
                                        <Typography style={{ lineHeight: '0.9rem', textAlign: 'center', color: 'white', fontWeight: 'bold', fontSize: '0.9rem' }}>
                                            {!blurred && getDates(event.date).date.split(' ').slice(0, 2).join(' ')}
                                        </Typography>
                                        <Typography style={{ textAlign: 'center', color: 'white', fontWeight: 500, fontSize: '0.7rem' }}>
                                            {!blurred && getDates(event.date) && getDates(event.date).time}
                                        </Typography>
                                    </Box>}
                                    {event.replayStatus == 'AVAILABLE' && <Box style={{ textAlign: 'center', color: 'white', fontWeight: 800, fontSize: '0.9rem' }}>
                                        replay disponible
                                    </Box>}

                                </Box>

                                <Box padding="0px 10px 0px 10px">

                                    <Typography style={{ textAlign: 'center', color: 'white', fontWeight: 600, fontSize: '0.7rem', lineHeight: '0.8rem' }}>
                                        {!blurred && event.title1}
                                    </Typography>
                                    <Box marginTop="5px">
                                        <Typography style={{ color: 'white', textAlign: 'center', fontWeight: 500, fontSize: '0.6rem', lineHeight: '0.7rem' }}>
                                            {!blurred &&event.title2}
                                        </Typography>

                                    </Box>

                                </Box>
                            </Box>
                            {spinner && <Box position="absolute" left="calc(50% - 40px)" top="calc(50% - 40px)">
                                <CircularProgress color="secondary" size={80}/>
                            </Box>}
                        </Box>
                        <Box flex={1} width="100%" visibility={isMobile ? 'hidden' : 'visible'} display="flex" flexDirection="row" alignItems="center" alignContent="center" justifyContent="center">


                            <DiscoverButton onClick={() => {
                                setSpinner(true)

                                navigate(event.fields.slug)
                            }}>
                                Découvrir
                            </DiscoverButton>
                            

                        </Box>

                    </Box>
                </Box>

            </Box></SwiperSlide>
    );


}

export default VignetteEvent