import React, {  useEffect, useState, useRef } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'



import randomColor from 'randomcolor'

import { regexList, cleanText } from '../../helpers/publicationHelper'


import useStyles from '../../components/style/pages/eventsSection'
import { navigate } from 'gatsby'

import { SwiperSlide } from 'swiper/react'

import { grey } from '@mui/material/colors';


const VignettePublication = ({ data: publication, position, index, parentIsMounted }) => {




    const classes = useStyles()
    const boxRef = useRef(null)
    const [isMounted, setIsMounted] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    
    //const image = null
    const image = `https://pekoia.com` + publication.images.find(child => child.original)?.original.src
  
    


    const finalUrl = publication.ogImage || image

    useEffect(() => {

        if (!isMounted)
            setIsMounted(true)


        if ((position > index - 7) && !isImageLoaded && finalUrl) {
            const img = new Image()
            img.onload = () => {
                setIsImageLoaded(true)
            }
            img.src = finalUrl

        }

    }, [position])

    
    const color = randomColor({ luminosity: 'light', hue: 'blue' })


    let regex = null
    let webSiteName = null
    let title = null
    if (publication.type === 'OPENGRAPH') {
        regex = regexList.find(regex => publication.url.match(regex))
        webSiteName = publication.url.match(regex) && publication.url.match(regex)[2]
        title = cleanText(publication.ogTitle)
    }
    else {
        webSiteName = publication.formateurName
        title = publication.imageTitle

    }



    


    return (<SwiperSlide><Box width='calc(100% - 20px)' height='100%' display="flex" flexDirection="column" >



        <Box width='100%' height='100%' style={{ cursor: 'pointer' }} onClick={() => { 
            
            console.log('@PUBLICATION')
            console.log(publication)
            navigate(publication.slug) }}>
            <a style={{ textDecoration: 'none' }} href={publication.url} target='_blank' rel="noreferrer">

                <Box width="100%" height="65%" ref={boxRef} >
                    {parentIsMounted && isImageLoaded && <img className={classes.webinarImage} src={finalUrl} alt={publication.title1} />}
                    {parentIsMounted && !isImageLoaded && <Box height="100%" color={color} bgcolor={color} display="flex" alignContent="center" alignItems="center">@</Box>}
                </Box>
                <Typography style={{ marginTop: '4px', fontSize: '0.7rem', fontWeight: 'bold', color: grey[800] }}>
                    {webSiteName}
                </Typography>
                <Box style={{ marginTop: '5px' }}>
                    <Typography style={{ fontSize: '0.7rem', lineHeight: '0.9rem', color: grey[600] }}>
                        {title}
                    </Typography>

                </Box>
            </a>
        </Box>

    </Box>
    </SwiperSlide>)
}

export default VignettePublication

