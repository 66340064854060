import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles'




const useStyles = makeStyles((theme) => {

  return ({

    componentContainer: {

      [theme.breakpoints.up('sm')]: {

        marginTop: '60px',
        paddingTop: '70px',


      }

    },

    titleContainer: {

      width: "70vw",
      marginBottom: "20px",
      marginTop: '10px'
    },

    titleTypography: {
      ontWeight: 600,
      textAlign: "center",
      fontSize: '2.5rem',
      [theme.breakpoints.up('sm')]: {

        fontSize: '3.2rem',



      }
    },

    mainContainer: {

      marginRight: '20px',
      marginTop: '10px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '50px',
        marginTop: '50px'
      }

    },
    publicationsContainer: {

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',

      [theme.breakpoints.up('md')]: {

      }

    },
    secondaryContainer: {
      width: "100%",
      borderBottom: `0.5px solid ${grey[400]}`,
      paddingBottom: "20px",
      marginBottom: "60px",
      [theme.breakpoints.up('sm')]: {
        width: "70vw",
        paddingBottom: "40px"
      }

    },
    specialityContainer: {
      marginTop: "30px",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignContent: "center",
      [theme.breakpoints.up('sm')]: {
        flexDirection: "row",
        marginTop: "70px",
        alignItems: 'center',
        justifyContent: 'center'
      }

    },
    hashContainer: {
      border: `1px solid ${grey[400]}`,
      margin: "3px 3px 3px 3px",
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px 12px 3px 12px",
      height: '25px',
      borderRadius: "15px",
      backgroundColor: grey[300],
      [theme.breakpoints.up('sm')]: {
        padding: "5px 12px 5px 12px",
        height: '30px'

      }
    },
    buttonsContainer: {
      marginLeft: "0%",
      marginTop: "20px",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up('sm')]: {
        marginLeft: "60%",
        marginTop: "20px",

      }
    }






  })

})


export const SuivreButton = styled(Button)({
  textTransform: 'none',
  backgroundColor: "#343434",
  width: '120px',
  color: '#CDCDCD',
  height: '40px',
  borderRadius: '30px',
  fontSize: '0.6rem',

  '&:hover': {
    backgroundColor: grey[700]
  }


})


export default useStyles
