import React from "react"
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useStyles from './formateursStyle'

import cleanHtml from '../../../components/SVGIcons/helpers'






const SectionHtml = ({ data }) => {

    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur


    const formateur = pageFormateur
    const classes = useStyles()

    let documentHtml = null
    documentHtml = cleanHtml(formateur.documentHtml)





    if (!documentHtml)
        return null



    return (
        <Box className={classes.htmlContainer}>
            <Typography variant="h2" >
                Parcours
            </Typography>
            <Box style={{ marginTop: '30px', fontFamily: 'Montserrat', lineHeight: '1.5rem', fontSize: '1rem' }}>
                <div dangerouslySetInnerHTML={{ __html: documentHtml }} />
            </Box>

        </Box>)
}

export default SectionHtml

