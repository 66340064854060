import React, { useState, useEffect } from "react"
import { indigo, grey } from '@mui/material/colors';
import Box from '@mui/material/Box'
import useStyles from './formateursStyle'
import CycleIcon from '../../../assets/cycle.svg'

import Carousel from '../../../components/caroussels/carousel'
import VignetteCycle from '../../../components/caroussels/vignetteCycle'




const SectionCycles = ({ data }) => {



    const classes = useStyles()
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {

        if (!isMounted)
            setIsMounted(true)

    }, [])



    if (!data)
        return null

    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur

    const formateur = pageFormateur
    const publications = pageFormateur?.publications

    if (!publications)
        return null

    const now = Date.now() / 1000


    let eventList = null
    const cycleList = data?.cycles


    // NOT ORDERED AT THIS STAGE
    const orderedList = cycleList?.edges
        .map(({ node }) => node)

        .filter(child => child.status === "PUBLISHED" || child.status === "PREPUBLISHED")
        .filter(child =>
            child.formateur
                .find(elem => elem?.fields?.formateurId === formateur?.fields.formateurId)

        )






    if (!(orderedList.length > 0))
        return null


    const sideData = {
        hideFormateur: true
    }


    return (
        <Box className={classes.componentContainer} >

            <div style={{ backgroundColor: 'black', marginTop: '50px', padding: "0px 20px 40px 20px" }}>
                <Carousel sideData={sideData} isMounted={isMounted} cellTemplate={VignetteCycle} cellsData={orderedList} carouselConfig={carouselConfigCycle} mode="dark" />
            </div>

        </Box>)
}

export default SectionCycles
const carouselConfigCycle = {
    title: "Cycles",
    titleMarginBottom: '60px',
    titleBorderBottom: `1px solid ${grey[600]}`,
    carouselHeight: 310,
    dots: true,
    cellRatio: 6/4,
    icon: CycleIcon,
    background: 'dark',
    titleStyle:{
        color: grey[100]
    },
    breakpoints: {
        50: {
            slidesPerView: 1.1,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        340: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        360: {
            slidesPerView: 1.3,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        380: {
            slidesPerView: 1.4,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        400: {
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        420: {
            slidesPerView: 1.6,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
            
        637.5: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
        800: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        1100: {
            slidesPerView: 3.25,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
      
        // when window width is >= 640px
        1500: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 10
        }
    }
}





