import React, {useState, useEffect} from "react"
import { indigo, grey } from '@mui/material/colors';
import WebinarIcon from '../../../assets/webinar.svg'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { GatsbyImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography'
import useStyles, { BlackSubscription } from './formateursStyle'

import { regexList, cleanText } from '../../../helpers/publicationHelper'
import Carousel from '../../../components/caroussels/carousel'
import VignettePublication from '../../../components/caroussels/vignettePublication'




const SectionPublications = ({ data }) => {

  

    const classes = useStyles()
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {

        if (!isMounted)
            setIsMounted(true)

    }, [])



    if (!data)
        return null

    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur

    const formateur = pageFormateur
    const publications = pageFormateur?.publications

    if (!publications)
        return null


    let publicationList = null
    const formateurName = data?.pageFormateur?.prenom + " " +data?.pageFormateur?.nom

    publicationList = formateur?.publicationList && JSON.parse(formateur.publicationList)

    if (!publicationList || !publicationList.length)
    return null


    const orderedPublicationList = publicationList
                                .map(index => publications.find(publication => publication.index === index))
                                .map(publication => ({...publication, formateurName: formateurName}))

 


    return (
        <Box className={classes.componentContainer} >
            {/* <Box className={classes.titleContainer}>
                <Typography variant="h1">
                     Publications et vidéos
                </Typography>

            </Box> */}
            {/* <Box className={classes.publicationsContainer}>

            

            {orderedPublicationList.map((publication) => <SinglePublication key={publication.index} publication={publication} />)}

        </Box> */}
       
       
        <Carousel isMounted={isMounted} cellTemplate={VignettePublication} cellsData={orderedPublicationList} carouselConfig={carouselConfig} />
       
        </Box>)
}

export default SectionPublications



const carouselConfig = {
    title: "Publications et vidéos",
    titleMarginBottom: '60px',
    titleBorderBottom: '1px solid black',
    carouselHeight: 300 ,
    dots: true,
    cellRatio: 1.5,
    icon: null,
    tippyContent: <Box padding="10px" >


        <p style={{ lineHeight: '1.1rem' }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has  <span style={{ color: indigo[600], fontWeight: 'bold' }}> la capacité est supérieure à 50 participants.</span>

        </p>
        <p style={{ lineHeight: '1.1rem' }}>
        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged

        </p>
    </Box>,
    breakpoints: {
        50: {
            slidesPerView: 1.25,
            slidesPerGroup: 1,
            spaceBetween: 10
        },
            
       800: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10
        },
        1050: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 10
        },
        // when window width is >= 480px
        1380: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 10
        },
        // when window width is >= 640px
        1600: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            spaceBetween: 10
        }
    }
}

