//Formateur


import React, {useState, useEffect, useContext} from "react"
import { graphql } from 'gatsby'
import Box from '@mui/material/Box'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import '../components/layout'
import SectionPersonal from './components/formateurs/sectionPersonal'
import SectionHtml from './components/formateurs/sectionHtml'
import SectionPublications from './components/formateurs/sectionPublications'
import SectionEvents from './components/formateurs/sectionEvents'
import SectionCycles from './components/formateurs/sectionCycles'
import { AuthContext } from '../context/general'
import {DEBUG} from '../buildSpecs'




import Layout from "../components/layout"
import Seo from "../components/seo"


import useStyles from './components/events/formateurStyle'



import FrontOfficeTheme from '../style/globalTheme'
import {fetchFormateurFollowsData} from './fetches/formateurFetch'
import Fade from '@mui/material/Fade';  
export const Head = ({ location, params, data, pageContext }) => {
  
    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur
    const title = pageFormateur.prenom + ' ' + pageFormateur.nom
  
    const slug = pageFormateur.fields.slug
    const pageDescription = pageFormateur.intro
  
    
    const imageSlugRaw = pageFormateur.children.find(child => child.original?.src.includes('originalC'))
    const imageSlugRawBackup = pageFormateur.children.find(child => child.original?.src.includes('originalLarge'))
  
  
    const imageURl = imageSlugRaw?.original.src || imageSlugRawBackup?.original.src
  
  
  
    return (
      <Seo title={'formateur: ' + title} slug={slug} description={pageDescription} image={imageURl} />
            
      )
  
  }


export default function Formateur({ data }) {

  const { authState } = useContext(AuthContext)
  const [followData, setFollowData] = useState(false)
  const [isFollowed, setIsFollowed] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const [keySequence, setKeySequence] = useState('')
  const [showInfo, setShowInfo] = useState(false)
  

  const classes = useStyles()




useEffect(()=>{


  
  setIsMounted(true)

},[])

  // KEY-HANDLER USED TO SHOW INFORMATION



  const handleKeyPress = (event) => {
    let newKeySequence = null
    if (keySequence.length < 7)
      newKeySequence = keySequence + event.key


    else
      newKeySequence = keySequence.substring(1, 7) + event.key

    setKeySequence(newKeySequence)

    if ((newKeySequence) == 'pekoia!')
      setShowInfo(true)
  }

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // END OF KEY-HANDLER USED TO SHOW INFORMATION

  useEffect (()=>{

    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur

   
    if (authState.user?.id){
      const slug = pageFormateur.fields.slug
      const formateurId = slug.split('/')[2]
      const clientId = authState.user?.id
      fetchFormateurFollowsData(formateurId, clientId, setIsFollowed)
      setFollowData({
        clientId: clientId
      })
    }

    
  },[authState])


  if (DEBUG){

    if (DEBUG == 1)
      return (<Box>DEBUG MODE</Box>)


  }

  

  if (!data)
    return null




  const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur


  const title = pageFormateur.prenom + ' ' + pageFormateur.nom

  const slug = pageFormateur.fields.slug

  const imageSlugRaw = pageFormateur.children.find(child => child.original?.src.includes('originalC'))
  const imageSlugRawBackup = pageFormateur.children.find(child => child.original?.src.includes('originalLarge'))


  const imageURl = imageSlugRaw?.original.src || imageSlugRawBackup?.original.src




  const pageDescription = pageFormateur.intro

  if (!isMounted)
  return null


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontOfficeTheme}>

        <Layout data={data}>
          {data.pageFormateur && <Fade in={showInfo}><Box padding='30px'>{data.pageFormateur.fields.formateurId}</Box></Fade>}


          <Box className={classes.pageContainer}>
      
             <SectionPersonal data={data} followData={followData} isFollowed={isFollowed} setIsFollowed={ setIsFollowed}/>
          {/* </div>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          > */}
              <SectionHtml data={data} />
          {/* </div>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          > */}
              <SectionPublications data={data} />
          {/* </div>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          > */}
             <SectionEvents data={data} />
             <SectionCycles data={data} />
          {/* </div> */}

             

             

             

          

          </Box>

        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );

}





export const pageQuery = graphql`
query ($slug: String!) {
  mainImage: imageSharp(original: { src: { regex: "/webinar/" } }) {
    gatsbyImageData
  }

  pageFormateur: dbFormateur(fields: { slug: { eq: $slug } })
  {
    nom
    prenom
    intro
    linkedin
    publicationList
    documentHtml
    hashList
    fields {
      formateurId
      slug
    }

    children {
      ... on ImageSharp {
        gatsbyImageData
        original {
          src
        }
      }

      ... on DBEvent {
        title1
        title2
        format
        internal {
          type
        }
        outil
        imageKey
        imageETag500
        replayStatus
        categoriesCode
        formateurId
        nbMax
        nbReserved
        imageETag300
        attendingNb
        date
        state
        format
        children {
          ... on DBFormateur {
            nom
            prenom
            state
            imageId
          }
          ... on ImageSharp {
            original {
              src
            }
          }
        }
        fields {
          eventId
          slug
        }
      }
    }
    publications: children {
      ... on DBPublications {
        type
        index
        url
        ogTitle
        ogImage
        imageTitle
        images: children {
          ... on ImageSharp {
            gatsbyImageData
            original {
              src
            }
          }
        }
      }
    }
  }
  originalPageFormateur: dbFormateur(fields: { originalSlug: { eq: $slug } })
  {
    nom
    prenom
    intro
    linkedin
    publicationList
    documentHtml
    hashList
    fields {
      formateurId
      slug
    }

    children {
      ... on ImageSharp {
        gatsbyImageData
        original {
          src
        }
      }

      ... on DBEvent {
        title1
        title2
        format
        internal {
          type
        }
        outil
        imageKey
        imageETag500
        replayStatus
        categoriesCode
        formateurId
        nbMax
        nbReserved
        imageETag300
        attendingNb
        date
        state
        format
        children {
          ... on DBFormateur {
            nom
            prenom
            state
            imageId
          }
          ... on ImageSharp {
            original {
              src
            }
          }
        }
        fields {
          eventId
          slug
        }
      }
    }
    publications: children {
      ... on DBPublications {
        type
        index
        url
        ogTitle
        ogImage
        imageTitle
        images: children {
          ... on ImageSharp {
            gatsbyImageData
            original {
              src
            }
          }
        }
      }
    }
  }

  cycles: allDbCycle(filter: { status: { eq: "PUBLISHED" } })
  {
    edges {
      node {
        title
        status
        replayStatus
        imageKey
        imageETag500
        children {
          ... on ImageSharp {
            original {
              src
            }
          }
        }
        formateur: children {
          ... on DBFormateur {
            nom
            prenom
            ... on DBFormateur {
              id
              nom
              prenom
              sanboxLemonwayId
              hashList
              intro
              fields {
                formateurId
                slug
              }
              children {
                ... on ImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        events: children {
          ... on DBEvent {
            title1
            title2
            replayStatus
            date
          }
        }
        fields {
          cycleId
          slug
        }
      }
    }
  }

  events: allDbEvent {
    edges {
      node {
        title1
        title2
        format
        outil
        imageKey
        imageETag500
        categoriesCode
        formateurId
        nbMax
        nbReserved
        imageETag300
        attendingNb
        replayStatus
        date
        state
        format
        children {
          ... on DBFormateur {
            nom
            prenom
            state
            imageId
          }
          ... on ImageSharp {
            original {
              src
            }
          }
        }
        fields {
          eventId
          slug
        }
      }
    }
  }
}

`





