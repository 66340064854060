import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import randomColor from 'randomcolor'

import { SwiperSlide } from 'swiper/react'

import Avatar from '@mui/material/Avatar'


import useStyles, {sxStyle} from '../../components/style/pages/eventsSection'
import { navigate } from 'gatsby'
import getDates from '../../helpers/dates'




const VignetteCycle = ({ data: event, mode, position, index, carouselHeight, sideData }) => {

    

    const classes = useStyles(mode)
    const isPageFormateur = sideData?.hideFormateur


    const [isMounted, setIsMounted] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const children = event.children
    const finalUrl = children?.find(image => (image.original && image.original.src.includes('L')))?.original.src

    const formateur = event.formateur.find(elem => elem.nom)
    const formateursImages = formateur.children.filter(elem => elem.gatsbyImageData)
    const formateurPortrait = formateursImages.find(elem => elem.original.src.includes('portrait'))?.original.src

    const now = new Date()


    const events = event.events.filter(elem => elem.title1)
        .sort((a, b) => a.date - b.date)
        .filter(elem => elem.date > now.getTime() / 1000)



    let nextDate = null
    nextDate = events.length && events[0].date

    const imageUrl = `https://www.pekoia.com/static/L${event.imageKey}-500-${event.imageETag500}.jpg`



    useEffect(() => {



        if (!isMounted)
            setIsMounted(true)

        if ((position > index - 7) && !isImageLoaded && imageUrl) {
            const img = new Image()



            img.onload = () => {
                setIsImageLoaded(true)
            }
            img.src = imageUrl

        }


    }, [position])

    const color = randomColor({ luminosity: 'light', hue: 'blue' })


    const textSize = 50



    if (!event.children)
        return null

 




    return (<SwiperSlide><Box width='100%' height={carouselHeight + 'px'} display="flex" flexDirection="column" >

        <Box width='100%' height='100%' style={{ cursor: 'pointer' }} onClick={() => {

            navigate(event.fields.slug)
        }} >
            <Box width="100%" height="60%" >
                {isMounted && isImageLoaded && <img className={classes.cycleImage} src={imageUrl || finalUrl} alt={event.title} />}
                {isMounted && !isImageLoaded && <Box height="100%" color={color} bgcolor={color} display="flex" alignContent="center" alignItems="center">@</Box>}


            </Box>
            <Box display="flex" flexDirection="row" width="100%" >
                {!isPageFormateur && <Box width='40px' position="relative" top="20px" left='5px'>

                    <Avatar style={{ zIndex: 2, margin: "5px", width: '40px', height: '40px' }}>
                        <Box position="relative" top="12px" left="3px" >
                            <img src={'https://www.pekoia.com' + formateurPortrait} alt={"portrait de " + formateur.prenom + " " + formateur.nom} style={{ transform: 'scale(1.2)' }} />
                        </Box>


                    </Avatar>
                </Box>}
                <Box width="calc(100% - 40px)" marginLeft="20px">
                    <Box marginTop="10px">
                        {event.replayStatus == 'AVAILABLE' && <Typography sx={sxStyle.cycleReplayTypography}>
                            replay disponible
                        </Typography>}
                        <Typography sx={sxStyle.titleTypography}>

                            {event.title.length > textSize ? event.title.slice(0, textSize) + '...' : event.title}
                        </Typography>


                    </Box>
                    {!isPageFormateur && <Box marginTop="0px">
                        <Typography sx={sxStyle.formateurTypography}>

                            {formateur.prenom}{" "} {formateur.nom}
                        </Typography>


                    </Box>}
                    {nextDate && <Box marginTop="5px">
                        <Typography sx={sxStyle.formateurTypography}>

                            prochain événement le {getDates(nextDate).dateShort} à {getDates(nextDate).time}
                        </Typography>


                    </Box>}
                 
                </Box>


            </Box>

            {/* </Box> */}

        </Box>


    </Box>
    </SwiperSlide>)
}

export default VignetteCycle




















