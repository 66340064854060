import React from "react"
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { GatsbyImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography'
import useStyles, { SuivreButton } from './formateursStyle'
import { addFollows, removeFollows } from '../../fetches/formateurFetch'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { grey } from '@mui/material/colors';




const SectionPersonal = ({ data, followData, isFollowed, setIsFollowed }) => {

    const pageFormateur = data.pageFormateur  ? data.pageFormateur : data.originalPageFormateur

    const formateur = pageFormateur

    const handleFollowClick = () => {

        const slug = pageFormateur.fields.slug
        const formateurId = slug.split('/')[2]

        if (isFollowed)
        {
            removeFollows(isFollowed.id)
            setIsFollowed(null)

        }
           
        if (!isFollowed)
            addFollows(followData.clientId, formateurId, setIsFollowed)


    }



    let gatsbyImageData = null
    gatsbyImageData = formateur.children.find(image => image.original?.src.includes('originalCropped'))?.gatsbyImageData
    if (!gatsbyImageData)
        gatsbyImageData = formateur.children.find(image => image.original?.src.includes('originalLarge'))?.gatsbyImageData




    const classes = useStyles()
    return (
        <Box className={classes.mainContainer}>


            <Box className={classes.secondaryContainer}>
                <Typography variant="h1" className={classes.titleTypography}>
                    {formateur.prenom.toUpperCase()}{" "}{formateur.nom.toUpperCase()}
                </Typography>
                {formateur.linkedin && <Box textAlign="center" >
                <a style={{ textDecoration: 'none', color: '#2474B3' }} href={formateur.linkedin} target='_blank' rel="noreferrer">
                    <LinkedInIcon/>
                </a>
                   

                </Box>}
                <Box className={classes.specialityContainer}>
                    <Typography variant="h2" style={{ fontWeight: 400, textAlign: "start", fontSize: '1rem' }}>
                        Spécialités:
                    </Typography>
                    <Box marginLeft="10px" padding="10px 0px 10px 0px" display="flex" flexDirection="row" flexWrap="wrap" alignContent="center" alignItems="center">
                        {formateur.hashList?.split('|').map((hash, index) => <Hash key={index} hash={hash} />)}
                    </Box>

                </Box>
                <Box marginTop="30px">
                    <Typography>
                        {formateur.intro}
                    </Typography>
                </Box>
                <Box marginTop="30px">


                    <Avatar style={{ width: '180px', border: '4px solid', height: '180px', zIndex: 2 }}>
                        <GatsbyImage
                            image={gatsbyImageData}
                            alt={formateur.nom ? formateur.nom : ''}
                            loading="lazy"


                        />

                    </Avatar>
                </Box>
                <Box width="100%">
                    <Box className={classes.buttonsContainer}>

                        <Box padding="5px">


                            {followData && <SuivreButton onClick={handleFollowClick}>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    {isFollowed && "Désabonner"}
                                    {!isFollowed && "Suivre"}
                                </Typography>

                            </SuivreButton>}
                        </Box>
                        <Box padding="5px">
                            <SuivreButton>
                                <Typography style={{ fontSize: '0.8rem' }}>
                                    Privatiser
                                </Typography>

                            </SuivreButton>
                        </Box>
                    </Box>
                </Box>


            </Box>

        </Box>)
}

export default SectionPersonal

const Hash = ({ hash }) => {

    const classes = useStyles()

    return (<Box className={classes.hashContainer}>
        <Typography variant="h3" style={{ fontSize: '0.8rem' }}>
            {hash}
        </Typography>

    </Box>)
}